import { useCallback, useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import { getDataFetchingClientRequestState } from '../../dataFetchingClient/utils/getDataFetchingClientRequestState';
import { GET_COLLECTIONS_QUERY } from '../queries/collectionsQuery';
export function useCollections(props) {
  const {
    loading,
    error,
    data,
    previousData
  } = useQuery(GET_COLLECTIONS_QUERY, {
    variables: props
  });
  const requestState = getDataFetchingClientRequestState(error, loading, !!data);
  const collections = useMemo(() => {
    const collectionsData = data || previousData;
    if (!collectionsData) {
      return [];
    } else {
      return collectionsData.collections.collections;
    }
  }, [data, previousData]);
  const getCollectionSlugs = useCallback(() => {
    return collections.reduce((slugs, collection) => {
      if (collection.publishedListing) {
        slugs.push(collection.publishedListing.slug);
      }
      return slugs;
    }, []);
  }, [collections]);
  const getDisplayedCollections = useCallback(() => {
    return collections.filter(collection => {
      var _collection$published;
      return (_collection$published = collection.publishedListing) === null || _collection$published === void 0 ? void 0 : _collection$published.displayed;
    }).sort((a, b) => b.publishedListing.updatedAt - a.publishedListing.updatedAt);
  }, [collections]);
  const getCollectionBySlug = useCallback(slug => {
    const collectionData = collections.find(collection => collection.publishedListing && collection.publishedListing.slug === slug);
    return collectionData;
  }, [collections]);
  const getCollectionById = useCallback(id => {
    const collectionData = collections.find(collection => collection.id === id);
    return collectionData;
  }, [collections]);
  return {
    data: collections,
    getCollectionById,
    getCollectionBySlug,
    getDisplayedCollections,
    getCollectionSlugs,
    requestState
  };
}