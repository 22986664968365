
(function() {
  var result = { translations: { "hi": { "i18n": { "duration": { "seconds": "{{ seconds }} \u0938\u0947\u0915\u0902\u0921", "minutes": "{{ minutes }} \u092E\u093F\u0928\u091F", "hours": "{{ hours }} \u0918\u0902\u091F\u093E", "hoursAndMinutes": "{{ hours }} \u0918\u0902\u091F\u093E {{ minutes }} \u092E\u093F\u0928\u091F", "days": "{{ days }} \u0926\u093F\u0928", "shortForm": { "seconds": "{{ seconds }}\u0938\u0947", "minutes": "{{ minutes }}\u092E\u093F", "hours": "{{ hours }}\u0918\u0902", "days": "{{ days }}\u0926\u093F", "hoursAndMinutes": "{{ hours }}\u0918\u0902 {{ minutes }}\u092E\u093F" } }, "dateTime": { "quarterAndYear": "\u0915\u094D\u0935\u093E{{quarterNumber}} {{yearNumber}}" }, "numberRepresentation": { "fraction": "{{ numerator }}/{{ denominator }}", "numberWithCurrencyCode": "{{currencyCode}} {{amountNumberValue}}", "phoneNumberWithExtension": "{{phoneNumber}}, \u090F\u0915\u094D\u0938\u091F\u0947\u0902\u0936\u0928 {{extension}}" } } } } };
  result.translationsLoaded = {
  "I18n": {
    "hi": "static-7.1147"
  }
};
  result.translationsAvailable = {
  "I18n": {
    "af": "static-7.1147",
    "ar-eg": "static-7.1147",
    "bg": "static-7.1147",
    "bn": "static-7.1147",
    "ca-es": "static-7.1147",
    "cs": "static-7.1147",
    "da": "static-7.1147",
    "de": "static-7.1147",
    "el": "static-7.1147",
    "en-gb": "static-7.1147",
    "en": "static-7.1147",
    "es-mx": "static-7.1147",
    "es": "static-7.1147",
    "et": "static-7.1147",
    "fi": "static-7.1147",
    "fr-ca": "static-7.1147",
    "fr": "static-7.1147",
    "he-il": "static-7.1147",
    "hi": "static-7.1147",
    "hr": "static-7.1147",
    "hu": "static-7.1147",
    "id": "static-7.1147",
    "it": "static-7.1147",
    "ja": "static-7.1147",
    "ko": "static-7.1147",
    "lt": "static-7.1147",
    "ms": "static-7.1147",
    "nl": "static-7.1147",
    "no": "static-7.1147",
    "pl": "static-7.1147",
    "pt-br": "static-7.1147",
    "pt-pt": "static-7.1147",
    "ro": "static-7.1147",
    "ru": "static-7.1147",
    "sk": "static-7.1147",
    "sl": "static-7.1147",
    "sv": "static-7.1147",
    "th": "static-7.1147",
    "tl": "static-7.1147",
    "tr": "static-7.1147",
    "uk": "static-7.1147",
    "vi": "static-7.1147",
    "x-pseudo": "static-7.1147",
    "zh-cn": "static-7.1147",
    "zh-hk": "static-7.1147",
    "zh-tw": "static-7.1147"
  }
};
  hns('I18n', result);
  if (typeof I18n === 'object' && I18n.trigger) { I18n.trigger('loaded:I18n:hi', {version: 'static-7.1147'}); }
})();


