import styled from 'styled-components';
import UIBox from 'UIComponents/layout/UIBox';
const FullscreenModalHeaderGroup = styled(UIBox).attrs(props => ({
  basis: props.middle ? 'auto' : 0,
  grow: props.middle ? 0 : 1,
  shrink: props.middle ? 1 : 0
})).withConfig({
  displayName: "FullscreenModalHeaderGroup",
  componentId: "sc-1jh3bz2-0"
})(["display:flex;flex-direction:", ";align-items:center;&&:last-of-type{justify-content:flex-end;}"], props => props.middle ? 'column' : 'row');
export default FullscreenModalHeaderGroup;