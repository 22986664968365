import quickFetch from 'quick-fetch';

// @ts-expect-error untyped dependency

import { getApiClient } from './apiClient';
let apiBase = 'ecosystem/v1';
export const setEcosystemApiBase = _apiBase => {
  apiBase = _apiBase;
};
export function getQuickFetch(requestName) {
  const earlyRequest = quickFetch.getRequestStateByName(requestName);
  if (!earlyRequest) return Promise.reject(new Error(`No early request found: ${requestName}`));
  return new Promise((resolve, reject) => {
    earlyRequest.whenFinished(result => {
      quickFetch.removeEarlyRequest(requestName);
      resolve(result);
    });
    earlyRequest.onError(() => {
      quickFetch.removeEarlyRequest(requestName);
      reject(new Error(`Early request failed: ${requestName}`));
    });
  });
}
export const getSearchTags = offeringType => {
  return getApiClient().get(`${apiBase}/${offeringType === 'MODULE' ? 'modules' : 'themes'}/search-tags`);
};
export const getUnifiedSearchTags = offeringType => {
  return getQuickFetch('search-tags').catch(() => {
    return getApiClient().post(`${apiBase}/assets/search-tags`, {
      data: Array.isArray(offeringType) ? offeringType : [offeringType]
    });
  });
};
export function getProviders(offeringType) {
  return getApiClient().post(`${apiBase}/providers/names`, {
    data: Array.isArray(offeringType) ? offeringType : [offeringType]
  });
}
export function getUnusedFilters() {
  return getQuickFetch('unused-filters').catch(() => {
    const path = `${apiBase}/themes/unused-filters`;
    return getApiClient().get(path);
  });
}
export const purchase = purchaseInfo => getApiClient().post(`${apiBase}/transactions/sales`, {
  data: purchaseInfo
});
export const getModuleDetails = slug => getApiClient().get(`${apiBase}/modules/details`, {
  query: {
    slug
  }
});
export const getModuleDetailsPreview = slug => getApiClient().get(`${apiBase}/modules/details/preview`, {
  query: {
    slug
  }
});
export function searchAssets(query) {
  return getApiClient().post(`${apiBase}/assets/search`, {
    data: query
  }).then(res => [res]);
}
export const getThemeDetails = slug => getApiClient().get(`${apiBase}/themes/details`, {
  query: {
    slug
  }
});
export const getThemeDetailsPreview = slug => getApiClient().get(`${apiBase}/themes/details/preview`, {
  query: {
    slug
  }
});
export const getVersionHistory = ({
  offeringId,
  offset,
  limit
}) => getApiClient().get(`${apiBase}/offerings/versions/history`, {
  query: {
    offeringId,
    offset,
    limit
  }
});
export function getCompatibility(offeringType) {
  return getApiClient().get(`${apiBase}/compatibility/${offeringType === 'THEME' ? 'themes' : 'modules'}`);
}